.wedding-inquiry-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}

.wedding-inquiry-content-div {
  flex: 1;
  margin-left: 5%;
}

.wedding-inquiry-img-div {
  flex: 1;
}

.wedding-inquiry-img {
  box-shadow: var(--bs);
  width: 100vw;
}

.email-link {
  color: hotpink;
}

.email-link:hover {
  color: var(--clr-medium);
}

@media (max-width: 850px) {
  .wedding-inquiry-div {
    flex-direction: column;
  }

  .wedding-inquiry-content-div {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .wedding-inquiry-div {
    flex-direction: column;
  }

  .wedding-inquiry-content-div {
    margin-left: 0;
  }

  .wedding-inquiry-img-div {
    flex: 0;
  }
}