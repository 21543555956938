@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --ff-secondary: 'Poppins', sans-serif;
  --ff-primary: 'Hammersmith One';

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #FAE3E3;
  --clr-medium: #ffa6a0;
  --clr-dark: #2a2b2a;
  --clr-accent: #CFA5B4;
  --clr-accent-two: white;

  --fs-h1: 2rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-h4: 1rem;
  --fs-body: .8rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4rem;
    --fs-h2: 3.75rem;
    --fs-h3: 2.5rem;
    --fs-h4: 2rem;
    --fs-body: 1.125rem;
  }
}

@media (min-width: 500px) {
  :root {
    --fs-h1: 3rem;
    --fs-h2: 2.75rem;
    --fs-h3: 2rem;
    --fs-h4: 1.5rem;
    --fs-body: 1rem;
    
  }
}

/* General styles */

html {
  scroll-behavior: smooth;
}

body {
  background: white;
  color: var(--clr-dark);
  margin: 0;
  font-family: var(--ff-secondary);
  font-size: var(--fs-body);
  line-height: 1.6;
}

section {
  padding: 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: var(--fw-bold);
}

:focus {
  outline: 3px solid var(--clr-accent);
  outline-offset: 3px;
}

/* Buttons */

.btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: var(--clr-medium);
  color: var(--clr-dark);
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: var(--fw-bold);
  transition: transform 200ms ease-in-out;
  box-shadow: var(--bs);
  border-radius: 10px;
  border: none;
}

.btn:hover {
  transform: scale(1.1);
}

/* Typography */


h1, h2, h3, h4 {
    line-height: 1.1;
    margin: 0;
    font-family: var(--ff-primary);
    text-transform: uppercase;
    font-style: italic;
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

h3 {
  font-size: var(--fs-h4);
}

p {
  font-size: 1.1em;
  line-height: 2em;
}

ul,
ol {
  line-height: 2em;
}

li {
  padding-left: 10px;
  margin-bottom: 1em;
}

/* Transform effects */

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}