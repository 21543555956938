.main-header {
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--clr-medium);
  padding: 0 1rem;
  box-shadow: var(--bs);
  z-index: 5;
  opacity: 0.8;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
