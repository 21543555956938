.logo-div {
  position: relative;
  height: 600px;
  width: 600px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 40%;
  margin-top: -300px;
  z-index: 4;
}

@media (max-width: 450px) {
  .logo-div {
    height: 400px;
    width: 400px;
    margin-left: -200px;
    margin-top: -200px;
  }
}
