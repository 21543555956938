.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 1em;
    border-radius: 5px;
}
  
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img {
    border-radius: 5px 5px 0 0;
}

.thumbnail-caption {
    text-align: center;
    color: black
}

@media (max-width: 850px) {
    .card {
        margin: 1em 0em;
    }
}
  