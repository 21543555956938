.wedding-thumbnail-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
}

@media (max-width: 1024px) {
	.wedding-thumbnail-gallery {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}
}

@media (max-width: 850px) {
	.wedding-thumbnail-gallery {
	  	display: block;
	}

	.wedding-thumbnail-gallery {    
		padding: 10px 0px;
	}
}
