.order-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}


.order-img-div {
  flex: 1;

}

.order-img {
  box-shadow: var(--bs);
  width: 200vw;
}

.order-form-div {
  margin-left: 5%;
  flex: 1;
}



.order-header-div,
.confirmation-div,
.order-button-div,
.confirmation-div {
  text-align: center;
  margin: 20px;
}

@media (max-width: 650px) {
  .order-div {
    flex-direction: column;
  }

  .order-form-div {
    margin-left: 0;
  }
}
