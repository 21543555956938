
.wedding-section {
    padding: 20px 0px;
}


.wedding-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
}

.wedding-img-container {    
	height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}


@media (max-width: 1024px) {
	.wedding-gallery {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}
}

@media (max-width: 850px) {
	.wedding-gallery {
	  	display: block;
	}

	.wedding-img-container {    
		padding: 10px 0px;
	}
}





