@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --ff-secondary: 'Poppins', sans-serif;
  --ff-primary: 'Hammersmith One';

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #FAE3E3;
  --clr-medium: #ffa6a0;
  --clr-dark: #2a2b2a;
  --clr-accent: #CFA5B4;
  --clr-accent-two: white;

  --fs-h1: 2rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-h4: 1rem;
  --fs-body: .8rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4rem;
    --fs-h2: 3.75rem;
    --fs-h3: 2.5rem;
    --fs-h4: 2rem;
    --fs-body: 1.125rem;
  }
}

@media (min-width: 500px) {
  :root {
    --fs-h1: 3rem;
    --fs-h2: 2.75rem;
    --fs-h3: 2rem;
    --fs-h4: 1.5rem;
    --fs-body: 1rem;
    
  }
}

/* General styles */

html {
  scroll-behavior: smooth;
}

body {
  background: white;
  color: #2a2b2a;
  color: var(--clr-dark);
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--ff-secondary);
  font-size: .8rem;
  font-size: var(--fs-body);
  line-height: 1.6;
}

section {
  padding: 5em 2em;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: 900;
  font-weight: var(--fw-bold);
}

:focus {
  outline: 3px solid #CFA5B4;
  outline: 3px solid var(--clr-accent);
  outline-offset: 3px;
}

/* Buttons */

.btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: #ffa6a0;
  background: var(--clr-medium);
  color: #2a2b2a;
  color: var(--clr-dark);
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  font-weight: var(--fw-bold);
  transition: transform 200ms ease-in-out;
  box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
  box-shadow: var(--bs);
  border-radius: 10px;
  border: none;
}

.btn:hover {
  transform: scale(1.1);
}

/* Typography */


h1, h2, h3, h4 {
    line-height: 1.1;
    margin: 0;
    font-family: 'Hammersmith One';
    font-family: var(--ff-primary);
    text-transform: uppercase;
    font-style: italic;
}

h1 {
  font-size: 2rem;
  font-size: var(--fs-h1);
}

h2 {
  font-size: 1.5rem;
  font-size: var(--fs-h2);
}

h3 {
  font-size: 1.25rem;
  font-size: var(--fs-h3);
}

h3 {
  font-size: 1rem;
  font-size: var(--fs-h4);
}

p {
  font-size: 1.1em;
  line-height: 2em;
}

ul,
ol {
  line-height: 2em;
}

li {
  padding-left: 10px;
  margin-bottom: 1em;
}

/* Transform effects */

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}
.main-header {
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--clr-medium);
  padding: 0 1rem;
  box-shadow: var(--bs);
  z-index: 5;
  opacity: 0.8;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  display: table-cell;
  position: relative;
  padding: 15px 0;
}

.nav-links ul {
  margin: 150px auto 0;
  padding: 0;
  list-style: none;
  display: table;
  width: 600px;
  text-align: center;
}

.nav-links a {
  color: var(--clr-accent-two);
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.19em;
  display: inline-block;
  padding: 12px 20px;
  position: relative;
}

.nav-links a:active, a:focus {
  outline: 0;
  border: none;
  color: var(--clr-accent-two);
}

.nav-links a:hover {
  color: var(--clr-accent-two);
}

.nav-links a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  background: var(--clr-accent-two);
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  width: 0;
}

.nav-links a:hover:after {
  width: 100%; 
  left: 0;
}

@media (min-width: 768px) {
  .nav-links ul {
    margin-top: 40px;
  }

  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 70%;
  background: var(--clr-medium);
  box-shadow: var(--bs);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.main-navigation__menu-btn {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 1rem;
  cursor: pointer;
}

#dropdown-icon {
  color: var(--clr-accent-two);
  font-size: 2.5em;
  z-index: 100;
}

#shop-icon {
  margin-top: 4px;
  text-align: center;
  font-size: 25px;
  color: var(--clr-accent-two);
}

.main-navigation__title {
  color: var(--clr-accent-two);
  letter-spacing: 0.6rem;
  padding: 2px;
}

.main-navigation__subheader {
  color: var(--clr-accent-two);
  letter-spacing: 0.1rem;
  line-height: 1.1;
  margin: 0;
  font-size: 0.8em;
  font-style: italic;
}

.headers-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.logo-div {
  position: relative;
  height: 600px;
  width: 600px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 40%;
  margin-top: -300px;
  z-index: 4;
}

@media (max-width: 450px) {
  .logo-div {
    height: 400px;
    width: 400px;
    margin-left: -200px;
    margin-top: -200px;
  }
}

.service-div {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 7vh;
  margin-bottom: 7vh;
  justify-items: center;
  justify-content: space-evenly;
  padding: 0 2em;
}

.location-wrapper {
  display: flex;
  flex-direction: row;
  margin: 4vh 0;
}

.location-div {
  flex: 2 1;
  width: 100%;
  margin: 0 3em 0 0;
}

.home-body-content {
  padding: 10px;
  line-height: 1.6;
}

.map-div {
  flex: 1 1;
  
}

.google-map {
  width: 100%;
  height: 100%;
  
  align-items: center;
  box-shadow: var(--bs);;
}

.hours-header-div {
  padding: 10px;
}

.service-header {
  padding: 5px;
  text-align: center;
  letter-spacing: 3px;
}

.time-header {
  padding: 5px;
  text-align: center;
  padding: 10px;
  color: var(--clr-dark);
  background: var(--clr-light);
  letter-spacing: 3px;
}

.hours-of-operation-body {
  width: 100%;
}

.service-button-div {
  display: flex;
  justify-content: center;
  margin: 0 auto;
} 

.service-button-btn {
    margin: 10px;
}

.service-header {
  
}

.email-link-service {
  color: var(--clr-medium);
}

.email-link-service:hover {
  color: hotpink;
}

.service-contact {
  font-size: 1.5em;
}

.service-contact-section {
  display: flex;
}

.service-contact-icon {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .service-button-div {
    display: block;
  }

  button.btn.service-button-btn {
    width: calc(100% - 10px);
    margin: 5px;
  }

  .service-contact {
    font-size: 1em;
  }

  .service-contact-icon {
    position: relative;
    left: -5px;
    padding-right: 5px;
  }

  .location-wrapper {
    flex-direction: column;
  }

  .location-div {
    margin: 0 0 1em 0;
  }

  .map-div {
    margin: 1em 0 0 0;
  }
}


.about-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}

.about-content-div {
  flex: 1 1;
  margin-left: 5%;
}

.about-img-div {
  flex: 1 1;
}

.about-img {
  box-shadow: var(--bs);
  width: 200vw;
}

@media (max-width: 850px) {
  .about-div {
    flex-direction: column;
  }

  .about-content-div {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .about-div {
    flex-direction: column;
  }

  .about-content-div {
    margin-left: 0;
  }
}

.order-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}


.order-img-div {
  flex: 1 1;

}

.order-img {
  box-shadow: var(--bs);
  width: 200vw;
}

.order-form-div {
  margin-left: 5%;
  flex: 1 1;
}



.order-header-div,
.confirmation-div,
.order-button-div,
.confirmation-div {
  text-align: center;
  margin: 20px;
}

@media (max-width: 650px) {
  .order-div {
    flex-direction: column;
  }

  .order-form-div {
    margin-left: 0;
  }
}

.wedding-inquiry-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}

.wedding-inquiry-content-div {
  flex: 1 1;
  margin-left: 5%;
}

.wedding-inquiry-img-div {
  flex: 1 1;
}

.wedding-inquiry-img {
  box-shadow: var(--bs);
  width: 100vw;
}

.email-link {
  color: hotpink;
}

.email-link:hover {
  color: var(--clr-medium);
}

@media (max-width: 850px) {
  .wedding-inquiry-div {
    flex-direction: column;
  }

  .wedding-inquiry-content-div {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .wedding-inquiry-div {
    flex-direction: column;
  }

  .wedding-inquiry-content-div {
    margin-left: 0;
  }

  .wedding-inquiry-img-div {
    flex: 0 1;
  }
}
.wedding-thumbnail-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	gap: 10px;
}

@media (max-width: 1024px) {
	.wedding-thumbnail-gallery {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		gap: 10px;
	}
}

@media (max-width: 850px) {
	.wedding-thumbnail-gallery {
	  	display: block;
	}

	.wedding-thumbnail-gallery {    
		padding: 10px 0px;
	}
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 1em;
    border-radius: 5px;
}
  
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img {
    border-radius: 5px 5px 0 0;
}

.thumbnail-caption {
    text-align: center;
    color: black
}

@media (max-width: 850px) {
    .card {
        margin: 1em 0em;
    }
}
  

.wedding-section {
    padding: 20px 0px;
}


.wedding-gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	gap: 10px;
}

.wedding-img-container {    
	height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}


@media (max-width: 1024px) {
	.wedding-gallery {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		gap: 10px;
	}
}

@media (max-width: 850px) {
	.wedding-gallery {
	  	display: block;
	}

	.wedding-img-container {    
		padding: 10px 0px;
	}
}






.footer {
  background: var(--clr-dark);
  color: var(--clr-accent-two);
  text-align: center;
  padding: .5em 0;
}

.footer a { 
  color: inherit; 
  text-decoration: none;
  border: none;
}

#icons {
  font-size: 1.8em;
}

#icons:hover {
  color: var(--clr-medium);
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  padding: 0;
}

.social-list__item {
  margin: 0 .5em;
}

.social-list__link {
  padding: 0.5em;
}

.copyright {
  font-size: .8rem;
}
