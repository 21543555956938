.service-div {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 7vh;
  margin-bottom: 7vh;
  justify-items: center;
  justify-content: space-evenly;
  padding: 0 2em;
}

.location-wrapper {
  display: flex;
  flex-direction: row;
  margin: 4vh 0;
}

.location-div {
  flex: 2;
  width: 100%;
  margin: 0 3em 0 0;
}

.home-body-content {
  padding: 10px;
  line-height: 1.6;
}

.map-div {
  flex: 1;
  
}

.google-map {
  width: 100%;
  height: 100%;
  
  align-items: center;
  box-shadow: var(--bs);;
}

.hours-header-div {
  padding: 10px;
}

.service-header {
  padding: 5px;
  text-align: center;
  letter-spacing: 3px;
}

.time-header {
  padding: 5px;
  text-align: center;
  padding: 10px;
  color: var(--clr-dark);
  background: var(--clr-light);
  letter-spacing: 3px;
}

.hours-of-operation-body {
  width: 100%;
}

.service-button-div {
  display: flex;
  justify-content: center;
  margin: 0 auto;
} 

.service-button-btn {
    margin: 10px;
}

.service-header {
  
}

.email-link-service {
  color: var(--clr-medium);
}

.email-link-service:hover {
  color: hotpink;
}

.service-contact {
  font-size: 1.5em;
}

.service-contact-section {
  display: flex;
}

.service-contact-icon {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .service-button-div {
    display: block;
  }

  button.btn.service-button-btn {
    width: calc(100% - 10px);
    margin: 5px;
  }

  .service-contact {
    font-size: 1em;
  }

  .service-contact-icon {
    position: relative;
    left: -5px;
    padding-right: 5px;
  }

  .location-wrapper {
    flex-direction: column;
  }

  .location-div {
    margin: 0 0 1em 0;
  }

  .map-div {
    margin: 1em 0 0 0;
  }
}
