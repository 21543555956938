.footer {
  background: var(--clr-dark);
  color: var(--clr-accent-two);
  text-align: center;
  padding: .5em 0;
}

.footer a { 
  color: inherit; 
  text-decoration: none;
  border: none;
}

#icons {
  font-size: 1.8em;
}

#icons:hover {
  color: var(--clr-medium);
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  padding: 0;
}

.social-list__item {
  margin: 0 .5em;
}

.social-list__link {
  padding: 0.5em;
}

.copyright {
  font-size: .8rem;
}