.about-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 2vh auto;
  justify-content: space-between;
}

.about-content-div {
  flex: 1;
  margin-left: 5%;
}

.about-img-div {
  flex: 1;
}

.about-img {
  box-shadow: var(--bs);
  width: 200vw;
}

@media (max-width: 850px) {
  .about-div {
    flex-direction: column;
  }

  .about-content-div {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .about-div {
    flex-direction: column;
  }

  .about-content-div {
    margin-left: 0;
  }
}
